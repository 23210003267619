import React from 'react';

function ServicesAbout() {
    return (
        <>
            <section className="appie-services-2-area pt-90 pb-55 md:px-20" id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <div className="appie-section-title">
                                <h3 className="appie-title">We’re driven by our values</h3>
                                <p>The app provides design and digital marketing. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 appie-single-service-about mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fal fa-tv"></i>
                                </div>
                                <h4 className="title">Seamless Integration</h4>
                                <p>Barqon integrates smoothly with your systems, ensuring a hassle-free experience.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 appie-single-service-about item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fal fa-code"></i>
                                </div>
                                <h4 className="title">Customizable User Experience</h4>
                                <p>Tailor your digital solutions with Barqon's flexible and adaptable platform.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 appie-single-service-about item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fal fa-user-friends"></i>
                                </div>
                                <h4 className="title">Real-Time Data Sync</h4>
                                <p>Keep your data updated across platforms with Barqon's real-time synchronization.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 appie-single-service-about item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fal fa-mobile"></i>
                                </div>
                                <h4 className="title">Automated Notifications</h4>
                                <p>Receive only the necessary alerts with Barqon's automated notification system.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 appie-single-service-about item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fal fa-retweet"></i>
                                </div>
                                <h4 className="title">Advanced Analytics</h4>
                                <p>Optimize operations with Barqon's insightful and actionable advanced analytics.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 appie-single-service-about item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fal fa-bell"></i>
                                </div>
                                <h4 className="title">Enhanced Security</h4>
                                <p>Barqon secures your data with robust protection and privacy measures.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesAbout;
