import React from 'react';

function ServicesHomeTwo({ className }) {
    return (
        <>
            <section className={`appie-services-2-area bg-gradient-to-r from-blue-50 via-purple-50 to-pink-50 pb-100 md:px-10 ${className}`} id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-12 text-center">
                            <div className="appie-section-title mx-auto mt-5 ">
                                <h3 className="appie-title font-bold ">What'll you get ?  </h3>
                               
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4">
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-bolt" />
                                </div>
                                <h4 className="title">Discover Barqon</h4>
                                <p>
                                Explore our AI platform to simplify feedback collection and management for your business.
                                </p>
                                <a href="#">
                                    Read More <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-home" />
                                </div>
                                <h4 className="title">Effortless Feedback</h4>
                                <p>
                                Effortlessly gather and manage genuine customer reviews to improve your business's reputation.


                                </p>
                                <a href="#">
                                    Read More <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-link" />
                                </div>
                                <h4 className="title">Enhance Visibility</h4>
                                <p>
                                Boost your business's Google ranking and reputation with AI-driven insights.
                                </p>
                                <a href="#">
                                    Read More <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-bell" />
                                </div>
                                <h4 className="title">Elevate Your Business</h4>
                                <p>
                                Start using Barqon today to reach new heights in your industry.
                                </p>
                                <a href="#">
                                    Read More <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-cog" />
                                </div>
                                <h4 className="title">Customize Your Experience</h4>
                                <p>
                                Tailor review requests and responses to fit your business needs.
                                </p>
                                <a href="#">
                                    Read More <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-lock" />
                                </div>
                                <h4 className="title">Monitor Progress</h4>
                                <p>
                                Track review metrics and ranking with our insightful dashboard.
                                </p>
                                <a href="#">
                                    Read More <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeTwo;

// import React from 'react';

// function ServicesHomeTwo({ className }) {
//     return (
//         <>
//             <section className={`relative overflow-hidden pb-24 md:px-10 ${className}`} id="service">
//                 <div className="absolute inset-0 bg-gradient-to-r from-blue-100 via-white to-blue-100 -z-10"></div>
//                 <div className="container mx-auto px-6">
//                     <div className="flex flex-col md:flex-row justify-between items-center mb-12">
//                         <div className="md:w-1/2 mb-8 md:mb-0">
//                             <div className="ml-5">
//                                 <h3 className="text-4xl font-extrabold text-gray-900 leading-tight">
//                                     How does it work
//                                 </h3>
//                                 <p className="text-lg text-gray-700 mt-4">
//                                     The full monty spiffing good time no biggie cack grub fantastic.
//                                 </p>
//                             </div>
//                         </div>
//                         <div className="md:w-1/2 text-center md:text-right">
//                             <a
//                                 className="bg-gradient-to-r from-purple-500 to-blue-500 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:from-purple-600 hover:to-blue-600 transition-transform duration-300 transform hover:scale-105"
//                                 href="#"
//                             >
//                                 View all Features <i className="fal fa-arrow-right ml-2" />
//                             </a>
//                         </div>
//                     </div>
//                     <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
//                         {[
//                             {
//                                 icon: "fas fa-bolt",
//                                 title: "Discover Barqon",
//                                 description: "Explore how our AI-powered platform simplifies feedback collection and management.",
//                                 delay: "200ms",
//                             },
//                             {
//                                 icon: "fas fa-home",
//                                 title: "Effortless Feedback",
//                                 description: "Gather and manage genuine customer reviews with ease.",
//                                 delay: "400ms",
//                             },
//                             {
//                                 icon: "fas fa-link",
//                                 title: "Enhance Visibility",
//                                 description: "Boost your business's Google ranking and reputation with AI-driven insights.",
//                                 delay: "600ms",
//                             },
//                             {
//                                 icon: "fas fa-bell",
//                                 title: "Elevate Your Business",
//                                 description: "Start using Barqon today to reach new heights in your industry.",
//                                 delay: "800ms",
//                             },
//                             {
//                                 icon: "fas fa-cog",
//                                 title: "Customize Your Experience",
//                                 description: "Tailor review requests and responses to fit your business needs.",
//                                 delay: "1000ms",
//                             },
//                             {
//                                 icon: "fas fa-lock",
//                                 title: "Monitor Progress",
//                                 description: "Track your review metrics and ranking improvements with our intuitive dashboard.",
//                                 delay: "1200ms",
//                             },
//                         ].map((service, index) => (
//                             <div
//                                 key={index}
//                                 className="relative bg-white p-6 rounded-2xl shadow-xl transition-transform duration-500 transform hover:scale-105 hover:shadow-2xl group"
//                                 style={{ animationDelay: service.delay }}
//                             >
//                                 <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-transparent opacity-50 group-hover:opacity-75 transition-opacity duration-300"></div>
//                                 <div className="relative z-10 flex items-center justify-center mb-4">
//                                     <div className="text-5xl text-blue-600 group-hover:text-blue-700 transition-colors duration-300">
//                                         <i className={service.icon} />
//                                     </div>
//                                 </div>
//                                 <h4 className="text-2xl font-semibold text-gray-900 mb-2">
//                                     {service.title}
//                                 </h4>
//                                 <p className="text-gray-600 mb-4">
//                                     {service.description}
//                                 </p>
//                                 <a
//                                     className="text-blue-600 hover:text-blue-700 flex items-center relative z-10"
//                                     href="#"
//                                 >
//                                     Read Moree <i className="fal fa-arrow-right ml-1" />
//                                 </a>
//                                 <div className="absolute inset-0 rounded-2xl border-2 border-blue-200 opacity-25 group-hover:opacity-50 transition-opacity duration-300"></div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }

// export default ServicesHomeTwo;
