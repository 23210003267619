// import React, { useRef } from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick-theme.css';
// import 'slick-carousel/slick/slick.css';
// import testmonialUser from '../../assets/images/testimonial-user-1.png';

// function TestimonialHomeTwo() {
//     const sliderRef = useRef();
//     const sliderNext = () => {
//         sliderRef.current.slickNext();
//     };
//     const sliderPrev = () => {
//         sliderRef.current.slickPrev();
//     };
//     const settings = {
//         autoplay: true,
//         arrows: false,
//         dots: false,
//     };
//     return (
//         <>
//             <section className="appie-testimonial-2-area mb-90" id="testimonial">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-lg-12">
//                             <div className="appie-testimonial-2-box">
//                                 <div
//                                     className="appie-testimonial-slider-2"
//                                     style={{ position: 'relative' }}
//                                 >
//                                     <span
//                                         onClick={sliderPrev}
//                                         className="prev slick-arrow"
//                                         style={{ display: 'block' }}
//                                     >
//                                         <i className="fal fa-arrow-left" />
//                                     </span>
//                                     <Slider ref={sliderRef} {...settings}>
//                                         <div className="appie-testimonial-slider-2-item">
//                                             <div className="item">
//                                                 <div className="thumb">
//                                                     <img src={testmonialUser} alt="" />
//                                                     <ul>
//                                                         <li>
//                                                             <i className="fas fa-star" />
//                                                         </li>
//                                                         <li>
//                                                             <i className="fas fa-star" />
//                                                         </li>
//                                                         <li>
//                                                             <i className="fas fa-star" />
//                                                         </li>
//                                                         <li>
//                                                             <i className="fas fa-star" />
//                                                         </li>
//                                                         <li>
//                                                             <i className="fas fa-star" />
//                                                         </li>
//                                                     </ul>
//                                                     <span>(4.7) review</span>
//                                                 </div>
//                                                 <div className="content">
//                                                     <p>
//                                                         Why I say old chap that is spiffing chip
//                                                         shop such a fibber the bee's knees, the
//                                                         wireless Richard fantastic do one cracking
//                                                         goal pukka baking cakes starkers mush don't
//                                                         get shirty with me argy bargy, I naff
//                                                         chimney pot blimey he lost his bottle cup.
//                                                     </p>
//                                                     <div className="author-info">
//                                                         <h5 className="title">Hanson Deck</h5>
//                                                         <span>Web developer</span>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="appie-testimonial-slider-2-item">
//                                             <div className="item">
//                                                 <div className="thumb">
//                                                     <img src={testmonialUser} alt="" />
//                                                     <ul>
//                                                         <li>
//                                                             <i className="fas fa-star" />
//                                                         </li>
//                                                         <li>
//                                                             <i className="fas fa-star" />
//                                                         </li>
//                                                         <li>
//                                                             <i className="fas fa-star" />
//                                                         </li>
//                                                         <li>
//                                                             <i className="fas fa-star" />
//                                                         </li>
//                                                         <li>
//                                                             <i className="fas fa-star" />
//                                                         </li>
//                                                     </ul>
//                                                     <span>(4.7) review</span>
//                                                 </div>
//                                                 <div className="content">
//                                                     <p>
//                                                         Why I say old chap that is spiffing chip
//                                                         shop such a fibber the bee's knees, the
//                                                         wireless Richard fantastic do one cracking
//                                                         goal pukka baking cakes starkers mush don't
//                                                         get shirty with me argy bargy, I naff
//                                                         chimney pot blimey he lost his bottle cup.
//                                                     </p>
//                                                     <div className="author-info">
//                                                         <h5 className="title">Hanson Deck</h5>
//                                                         <span>Web developer</span>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </Slider>
//                                     <span
//                                         onClick={sliderNext}
//                                         className="next slick-arrow"
//                                         style={{ display: 'block' }}
//                                     >
//                                         <i className="fal fa-arrow-right" />
//                                     </span>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }

// export default TestimonialHomeTwo;

import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import testmonialUser1 from '../../assets/images/testimony1.jpg';
import testmonialUser2 from '../../assets/images/testimony2.jpg';
import testmonialUser3 from '../../assets/images/testimony1.jpg';
import testmonialUser4 from '../../assets/images/testimony4.jpg';
import testmonialUser5 from '../../assets/images/testimony1.jpg';
import testmonialUser6 from '../../assets/images/testimony1.jpg';


function TestimonialHomeTwo() {
    const sliderRef = useRef();
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    const settings = {
        autoplay: true,
        arrows: false,
        dots: false,
    };
    return (
        <>
            <section className="appie-testimonial-2-area mb-90 md:px-10" id="testimonial">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-testimonial-2-box">
                                <div
                                    className="appie-testimonial-slider-2"
                                    style={{ position: 'relative' }}
                                >
                                    <span
                                        onClick={sliderPrev}
                                        className="prev slick-arrow"
                                        style={{ display: 'block' }}
                                    >
                                        <i className="fal fa-arrow-left" />
                                    </span>
                                    <Slider ref={sliderRef} {...settings}>
                                        <div className="appie-testimonial-slider-2-item">
                                            <div className="item">
                                                <div className="thumb">
                                                    <img src={testmonialUser1} alt="" />
                                                    <ul>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                    <span>(4.7) review</span>
                                                </div>
                                                <div className="content">
                                                    <p>
                                                    Big fan of how they boosted my google ranking, seriously. Getting more reviews from customers too. Love their organic approach, seen some solid results. Nice to find a service that delivers on their promises.                                                    </p>
                                                    <div className="author-info">
                                                        <h5 className="title">David Pfeifer</h5>
                                                        <span>Freelancer</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="appie-testimonial-slider-2-item">
                                            <div className="item">
                                                <div className="thumb">
                                                    <img src={testmonialUser2} alt="" />
                                                    <ul>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                    <span>(4.7) review</span>
                                                </div>
                                                <div className="content">
                                                    <p>
                                                    Barqon offers a truly innovative solution. Their system boosted my reviews and delivered great results. Saw a real difference in my Google ranking, which was delightful. Additionally, the founders are quite helpful and supportive. Definitely worth trying                                                    </p>
                                                    <div className="author-info">
                                                        <h5 className="title">Lily Donald</h5>
                                                        <span>Business owner</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="appie-testimonial-slider-2-item">
                                            <div className="item">
                                                <div className="thumb">
                                                    <img src={testmonialUser3} alt="" />
                                                    <ul>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                    <span>(4.7) review</span>
                                                </div>
                                                <div className="content">
                                                    <p>
                                                    Barqon delivers great results. My ranking on Google soared and the number of reviews on my page grew. Their use of new ways to get the job done is quite impressive. Heavenly experience, highly recommend.                                                    </p>
                                                    <div className="author-info">
                                                        <h5 className="title">Sarah Johnson</h5>
                                                        <span>Business Owner</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="appie-testimonial-slider-2-item">
                                            <div className="item">
                                                <div className="thumb">
                                                    <img src={testmonialUser4} alt="" />
                                                    <ul>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                    <span>(4.7) review</span>
                                                </div>
                                                <div className="content">
                                                    <p>
                                                    So glad I used these guys! Noticed a definite uptick in reviews on here. And let's not forget about the impact on the Google ranking, seriously, it's gone way up. Great results all around. Good job, Barqon!                                                    </p>
                                                    <div className="author-info">
                                                        <h5 className="title">Michael Smith</h5>
                                                        <span>Entrepreneur</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="appie-testimonial-slider-2-item">
                                            <div className="item">
                                                <div className="thumb">
                                                    <img src={testmonialUser5} alt="" />
                                                    <ul>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                    <span>(4.7) review</span>
                                                </div>
                                                <div className="content">
                                                    <p>
                                                    Seen a lot more reviews rollin in since using these guys. Great job, keep it up.                                                    </p>
                                                    <div className="author-info">
                                                        <h5 className="title">Emily Davis</h5>
                                                        <span>Marketing Manager</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="appie-testimonial-slider-2-item">
                                            <div className="item">
                                                <div className="thumb">
                                                    <img src={testmonialUser6} alt="" />
                                                    <ul>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                    <span>(4.7) review</span>
                                                </div>
                                                <div className="content">
                                                    <p>
                                                    Really impressed with the founders here, super helpful. Noticed a tangible growth in reviews and ranking on Google. That's all organic and innovative, folks! The results speak for themselves. Thumbs up.                                                    </p>
                                                    <div className="author-info">
                                                        <h5 className="title">Anna Wilson</h5>
                                                        <span>Freelancer</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                    <span
                                        onClick={sliderNext}
                                        className="next slick-arrow"
                                        style={{ display: 'block' }}
                                    >
                                        <i className="fal fa-arrow-right" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TestimonialHomeTwo;
