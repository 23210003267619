// import React from 'react';
// import projectThumb from '../../assets/images/last (2).jpg';

// function ProjectHome({ className }) {
//     return (
//         <>
//             <section className={`appie-project-area pb-100 md:px-10 ${className || ''}`}>
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-lg-12">
//                             <div
//                                 className="appie-project-box wow animated slideInUp"
//                                 data-wow-duration="1000ms"
//                                 data-wow-delay="0ms"
//                             >
//                                 <div className="row">
//                                     <div className="col-lg-6">
//                                         <div className="appie-project-content">
//                                             <h3 className="title">
//                                                 Start your project with Barqon.
//                                             </h3>
//                                             <p>We will email you only about this product.</p>
//                                             <form action="#">
//                                                 <div className="input-box mt-30">
//                                                     <input type="text" placeholder="Your Email" />
//                                                     <button type="button">Subscribe</button>
//                                                 </div>
//                                             </form>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="appie-project-thumb">
//                                     <img src={projectThumb} alt="" />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }

// export default ProjectHome;

import React, { useState } from 'react';
import axios from 'axios';
import projectThumb from '../../assets/images/last (2).jpg';

function ProjectHome({ className }) {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://qrps.ca:5000/api/users/submit-email', { email });
            setSuccess(response.data.message);
            setEmail('');  // Clear the input field
        } catch (error) {
            console.error('Error submitting form:', error);
            setError(error.response?.data?.error || 'An unexpected error occurred');
        }
    };

    return (
        <>
            <section className={`appie-project-area pb-100 md:px-10 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-project-box wow animated slideInUp"
                                data-wow-duration="1000ms"
                                data-wow-delay="0ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="appie-project-content">
                                            <h3 className="title">
                                                Start your project with Barqon.
                                            </h3>
                                            <p>We will email you only about this product.</p>
                                            <form onSubmit={handleSubmit}>
                                                <div className="input-box mt-30">
                                                    <input
                                                        type="email"
                                                        placeholder="Your Email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                    <button type="submit">Subscribe</button>
                                                </div>
                                            </form>
                                            {success && <p className="text-green-500 mt-2">{success}</p>}
                                            {error && <p className="text-red-500 mt-2">{error}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="appie-project-thumb">
                                    <img src={projectThumb} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProjectHome;

