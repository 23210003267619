// import React from 'react';
// import { Link } from 'react-router-dom';
// import thumb from '../../assets/images/about-thumb-5.png';



// function HeroNews({ title, breadcrumb = [] }) {
//     return (
//         <>
//             <div className="appie-page-title-area md:px-10">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-lg-7">
//                             <div className="appie-page-title-item">
//                                 <h3 className="title">{title}</h3>
//                                 <nav aria-label="breadcrumb">
//                                     <ol className="breadcrumb">
//                                         {breadcrumb.map((value) => (
//                                             <li key={Math.random()} className="breadcrumb-item">
//                                                 <Link to={value.link}>{value.title}</Link>
//                                             </li>
//                                         ))}
//                                     </ol>
//                                 </nav>
//                                 <div className="col-lg-5">
//                             <div className="thumb">
//                                 <img src={thumb} alt="Thumbnail" />
//                             </div>
//                         </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default HeroNews;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import thumb from '../../assets/images/about-thumb-5.png';

// function HeroNews({ title, breadcrumb = [] }) {
//     return (
//         <>
//             <div className="appie-page-title-area md:px-10">
//                 <div className="container">
//                     <div className="row align-items-center">
//                         <div className="col-lg-7">
//                             <div className="appie-page-title-item">
//                                 <h3 className="title">{title}</h3>
//                                 <nav aria-label="breadcrumb">
//                                     <ol className="breadcrumb">
//                                         {breadcrumb.map((value) => (
//                                             <li key={Math.random()} className="breadcrumb-item">
//                                                 <Link to={value.link}>{value.title}</Link>
//                                             </li>
//                                         ))}
//                                     </ol>
//                                 </nav>
//                             </div>
//                         </div>
//                         <div className="col-lg-5">
//                             <div className="thumb">
//                                 <img src={thumb} alt="Thumbnail" />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default HeroNews;
import React from 'react';
import { Link } from 'react-router-dom';
import thumb from '../../assets/images/about-thumb-5.png';
// import heroMan from '../../assets/images/hero-mans.png';
// import heroThumb from '../../assets/images/hero-thumb-3.png';
import shapeTen from '../../assets/images/shape/shape-10.png';
import shapeEleven from '../../assets/images/shape/shape-11.png';
import shapeTwelve from '../../assets/images/shape/shape-12.png';
import shapeNine from '../../assets/images/shape/shape-9.png';
import './HeroNews.css'; // Import the CSS file

function HeroNews({ title = "Default Title" }) {
    return (
        <> 
            <section className="appie-page-title-area md:px-20 relative">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="appie-hero-content-2">
                                {/* <span>14 day free</span> */}
                                <h1 className="title text-6xl font-extrabold">{title}</h1>
                                {/* <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        {breadcrumb.map((value, index) => (
                                            <li key={index} className="breadcrumb-item">
                                                <Link to={value.link}>{value.title}</Link>
                                            </li>
                                        ))}
                                    </ol>
                                </nav> */}
                                <div className="hero-users">
                                    {/* <img src={heroMan} alt="Hero Man" /> */}
                                    {/* <span>
                                        30k <span> Feedback</span>
                                    </span> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="thumb">
                                <img src={thumb} alt="Thumbnail" />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="appie-hero-thumb-3 wow animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="400ms"
                >
                    {/* <img src={heroThumb} alt="Hero Thumb" /> */}
                </div>
                <div className="shape shape-9">
                    <img src={shapeNine} alt="Shape Nine" />
                </div>
                <div className="shape shape-10">
                    <img src={shapeTen} alt="Shape Ten" />
                </div>
                <div className="shape shape-11">
                    <img src={shapeEleven} alt="Shape Eleven" />
                </div>
                <div className="shape shape-12">
                    <img src={shapeTwelve} alt="Shape Twelve" />
                </div>
            </section>
        </>
    );
}

export default HeroNews;





// import React from 'react';
// import { motion } from 'framer-motion';

// function HeroNews({ title }) {
//     return (
//         <div className="relative bg-gradient-to-r from-teal-300 via-cyan-300 to-sky-300 text-gray-800 overflow-hidden">
            
//             <div className="absolute inset-0 bg-opacity-30 z-0"></div>
//             <motion.div
//                 initial={{ opacity: 0, y: 20 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.8, ease: 'easeOut' }}
//                 className="container relative z-10 py-16 px-4 sm:px-6 lg:px-8"
//             >
//                 <div className="text-center">
//                     <h1 className="text-4xl md:text-5xl font-extrabold leading-tight mb-4 mt-5">
//                         {title}
//                     </h1>
//                     <p className="text-lg md:text-xl mb-8 max-w-3xl mx-auto">
//                         Discover the latest updates and get in touch with us for any inquiries. Our contact page is designed to provide you with all the information you need.
//                     </p>
//                     <a href="/contacts" className="inline-block bg-gray-800 text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-gray-700 transition-colors duration-300">
//                         Get in Touch
//                     </a>
//                 </div>
//             </motion.div>
//             </div>
        
//     );
// }

// export default HeroNews;

