// import React from 'react';
// // import aboutThumb from '../../assets/images/stock2.jpg';


// function HeroAbout() {
//     return (
//         <>
//             <div className="appie-about-top-title-area md:px-20">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-lg-7">
//                             <div className="appie-about-top-title">
//                                 <h2 className="title">Barqon – AI-Optimized Reviews to Skyrocket Your Search Rankings!"</h2>
//                                 <p>We offer you a panoply of cutting-edge options.</p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <section className="appie-about-page-area md:px-20">
                
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-lg-8">
//                             <div className="appie-about-page-content">
//                                 <h3 className="title">
//                                     Join our team to create the best digital solutions.
//                                 </h3>
//                                 <p>
//                                     He nicked it up the duff a load of old tosh bleeder butty bugger
//                                     all mate cheeky bugger bodge tickety boo, mush well Richard
//                                     geeza buggered haggle david you mug I, such a fibber my good sir
//                                     knackered down the pub baking cakes chancer golly gosh. Car boot
//                                     give us a bell bits and hanky panky me old mucker fantastic in
//                                     my flat so I said nice one he lost his bottle.
//                                 </p>
//                                 <a href="#">
//                                     View all Members <i className="fal fa-arrow-right"></i>
//                                 </a>
                                
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }

// export default HeroAbout;


// import React from 'react';
// import shapeFour from '../../assets/images/shape/shape-4.png';
// import shapeSix from '../../assets/images/shape/shape-6.png';
// import shapeSeven from '../../assets/images/shape/shape-7.png';
// import shapeEight from '../../assets/images/shape/shape-8.png';
// import thumb from '../../assets/images/fun-fact-thumb.png';

// import './HeroAbout.css';

// function HeroAbout() {
//     return (
//         <>
//             <div className="appie-about-top-title-area md:px-20 relative">
//                 <div className="container">
//                     <div className="row">
                    
//                         <div className="col-lg-6">
                            
//                         <div className="appie-about-top-title">
//                                 <h2 className="title text-4xl font-extrabold text-gray-900 leading-tight">
//                                     Barqon – AI-Optimized Reviews to Skyrocket Your Search Rankings!
//                                 </h2>
//                                 <div className="thumb">
//                                     <img src={thumb} alt="" />
//                                 </div>
                                
                                
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="absolute inset-0 pointer-events-none overflow-hidden shapes-container">
//                 <img src={shapeFour} alt="Shape Four" className="shape shape-4" />
//                 <img src={shapeSix} alt="Shape Six" className="shape shape-6" />
//                 <img src={shapeSeven} alt="Shape Seven" className="shape shape-7" />
//                 <img src={shapeEight} alt="Shape Eight" className="shape shape-8" />
//             </div>
//             </div>
//             <section className="appie-about-page-area md:px-20">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-lg-8">
//                             <div className="appie-about-page-content">
//                                 <h3 className="title">
//                                     Join our team to create the best digital solutions.
//                                 </h3>
//                                 <p>
//                                 At Barqon, we believe in the transformative power of AI to elevate businesses to new heights. Our cutting-edge platform leverages AI technology to generate authentic, SEO-optimized customer reviews that enhance your online presence and boost your Google rankings. We're passionate about innovation and are dedicated to providing digital solutions that make a real impact. Join us in our mission to revolutionize the way businesses connect with their customers and achieve unprecedented success in the digital landscape. Together, we can create the best digital solutions and set new standards in the industry.
//                                 </p>
//                                 <a href="#">
//                                     View all Members <i className="fal fa-arrow-right"></i>
//                                 </a>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }

// export default HeroAbout;

import React from 'react';
import shapeFour from '../../assets/images/shape/shape-4.png';
import shapeSix from '../../assets/images/shape/shape-6.png';
import shapeSeven from '../../assets/images/shape/shape-7.png';
import shapeEight from '../../assets/images/shape/shape-8.png';
import thumb from '../../assets/images/fun-fact-thumb.png';

import './HeroAbout.css';

function HeroAbout() {
    return (
        <>
            <div className="appie-about-top-title-area md:px-20 relative">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="appie-about-top-title">
                                <h2 className="title text-4xl font-extrabold text-gray-900 leading-tight">
                                    Barqon – AI-Optimized Reviews to Skyrocket Your Search Rankings!
                                </h2>
                                {/* <p>We offer you a panoply of cutting-edge options.</p> */}
                            </div>
                        </div>
                        <div className="col-lg-5 pt-5">
                            <div className="thumb">
                                <img src={thumb} alt="Thumbnail" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute inset-0 pointer-events-none overflow-hidden shapes-container">
                    <img src={shapeFour} alt="Shape Four" className="shape shape-4" />
                    <img src={shapeSix} alt="Shape Six" className="shape shape-6" />
                    <img src={shapeSeven} alt="Shape Seven" className="shape shape-7" />
                    <img src={shapeEight} alt="Shape Eight" className="shape shape-8" />
                </div>
            </div>
            <section className="appie-about-page-area custom-mt md:px-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="appie-about-page-content">
                                <h3 className="title">
                                    Join our team to create the best digital solutions.
                                </h3>
                                <p>
                                    At Barqon, we believe in the transformative power of AI to elevate businesses to new heights. Our cutting-edge platform leverages AI technology to generate authentic, SEO-optimized customer reviews that enhance your online presence and boost your Google rankings. We're passionate about innovation and are dedicated to providing digital solutions that make a real impact. Join us in our mission to revolutionize the way businesses connect with their customers and achieve unprecedented success in the digital landscape. Together, we can create the best digital solutions and set new standards in the industry.
                                </p>
                                <a href="#">
                                    View all Members <i className="fal fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;
