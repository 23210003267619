// import React from 'react';
// import team1 from '../../assets/images/team-1.jpg';
// import team2 from '../../assets/images/team-2.jpg';
// import team3 from '../../assets/images/team-3.jpg';
// import team4 from '../../assets/images/team-4.jpg';

// function TeamAbout() {
//     return (
//         <>
//             <section className="appie-team-area appie-team-about-area pb-90 md:px-10">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-lg-12">
//                             <div className="appie-section-title text-center">
//                                 <h3 className="appie-title">Meet our Team Members</h3>
//                                 <p>Different layouts and styles for team sections.</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="row">
//                         <div className="col-lg-3 col-md-6">
//                             <div
//                                 className="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp"
//                                 data-wow-duration="2000ms"
//                                 data-wow-delay="200ms"
//                             >
//                                 <div className="thumb">
//                                     <img src={team1} alt="" />
//                                     <ul>
//                                         <li>
//                                             <a href="#">
//                                                 <i className="fab fa-facebook-f"></i>
//                                             </a>
//                                         </li>
//                                         <li>
//                                             <a href="#">
//                                                 <i className="fab fa-twitter"></i>
//                                             </a>
//                                         </li>
//                                         <li>
//                                             <a href="#">
//                                                 <i className="fab fa-pinterest-p"></i>
//                                             </a>
//                                         </li>
//                                     </ul>
//                                 </div>
//                                 <div className="content text-left">
//                                     <h5 className="title">Benjamin Evalent</h5>
//                                     <span>CEO-Founder</span>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-lg-3 col-md-6">
//                             <div
//                                 className="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp"
//                                 data-wow-duration="2000ms"
//                                 data-wow-delay="400ms"
//                             >
//                                 <div className="thumb">
//                                     <img src={team2} alt="" />
//                                     <ul>
//                                         <li>
//                                             <a href="#">
//                                                 <i className="fab fa-facebook-f"></i>
//                                             </a>
//                                         </li>
//                                         <li>
//                                             <a href="#">
//                                                 <i className="fab fa-twitter"></i>
//                                             </a>
//                                         </li>
//                                         <li>
//                                             <a href="#">
//                                                 <i className="fab fa-pinterest-p"></i>
//                                             </a>
//                                         </li>
//                                     </ul>
//                                 </div>
//                                 <div className="content text-left">
//                                     <h5 className="title">Benjamin Evalent</h5>
//                                     <span>CEO-Founder</span>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-lg-3 col-md-6">
//                             <div
//                                 className="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp"
//                                 data-wow-duration="2000ms"
//                                 data-wow-delay="600ms"
//                             >
//                                 <div className="thumb">
//                                     <img src={team3} alt="" />
//                                     <ul>
//                                         <li>
//                                             <a href="#">
//                                                 <i className="fab fa-facebook-f"></i>
//                                             </a>
//                                         </li>
//                                         <li>
//                                             <a href="#">
//                                                 <i className="fab fa-twitter"></i>
//                                             </a>
//                                         </li>
//                                         <li>
//                                             <a href="#">
//                                                 <i className="fab fa-pinterest-p"></i>
//                                             </a>
//                                         </li>
//                                     </ul>
//                                 </div>
//                                 <div className="content text-left">
//                                     <h5 className="title">Benjamin Evalent</h5>
//                                     <span>CEO-Founder</span>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-lg-3 col-md-6">
//                             <div
//                                 className="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp"
//                                 data-wow-duration="2000ms"
//                                 data-wow-delay="800ms"
//                             >
//                                 <div className="thumb">
//                                     <img src={team4} alt="" />
//                                     <ul>
//                                         <li>
//                                             <a href="#">
//                                                 <i className="fab fa-facebook-f"></i>
//                                             </a>
//                                         </li>
//                                         <li>
//                                             <a href="#">
//                                                 <i className="fab fa-twitter"></i>
//                                             </a>
//                                         </li>
//                                         <li>
//                                             <a href="#">
//                                                 <i className="fab fa-pinterest-p"></i>
//                                             </a>
//                                         </li>
//                                     </ul>
//                                 </div>
//                                 <div className="content text-left">
//                                     <h5 className="title">Benjamin Evalent</h5>
//                                     <span>CEO-Founder</span>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-lg-12">
//                             <div className="team-btn text-center mt-50">
//                                 <a className="main-btn" href="#">
//                                     View all Members <i className="fal fa-arrow-right"></i>
//                                 </a>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }

// export default TeamAbout;

import React from 'react';
import team1 from '../../assets/images/team-1.jpg';
import team2 from '../../assets/images/team-2.jpg';
import team3 from '../../assets/images/team-3.jpg';
import team4 from '../../assets/images/team-4.jpg';

function TeamAbout() {
    return (
        <>
            <section className="appie-team-area appie-team-about-area pb-90 md:px-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Meet our Team Members</h3>
                                <p>Different layouts and styles for team sections.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* Team Members Grid */}
                        <div className="col-lg-12">
                            <div className="team-container flex flex-wrap justify-center gap-4 md:gap-6">
                                <div className="team-item w-40 md:w-1/4">
                                    <div className="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp">
                                        <div className="thumb relative">
                                            <img className="w-full h-full object-cover rounded-lg" src={team1} alt="" />
                                            <ul className="absolute bottom-4 left-4 flex gap-2">
                                                <li>
                                                    <a href="#" className="text-white bg-blue-600 p-2 rounded-full">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="text-white bg-blue-400 p-2 rounded-full">
                                                        <i className="fab fa-twitter"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="text-white bg-red-600 p-2 rounded-full">
                                                        <i className="fab fa-pinterest-p"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="content text-center mt-4">
                                            <h5 className="title text-lg font-semibold">Aditya</h5>
                                            <span className="text-sm text-gray-600">CEO-Founder</span>
                                        </div>
                                    </div>
                                </div>
                                {/* Repeat the team-item div for each team member */}
                                <div className="team-item w-40 md:w-1/4">
                                    <div className="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp">
                                        <div className="thumb relative">
                                            <img className="w-full h-full object-cover rounded-lg" src={team2} alt="" />
                                            <ul className="absolute bottom-4 left-4 flex gap-2">
                                                <li>
                                                    <a href="#" className="text-white bg-blue-600 p-2 rounded-full">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="text-white bg-blue-400 p-2 rounded-full">
                                                        <i className="fab fa-twitter"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="text-white bg-red-600 p-2 rounded-full">
                                                        <i className="fab fa-pinterest-p"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="content text-center mt-4">
                                            <h5 className="title text-lg font-semibold">John Doe</h5>
                                            <span className="text-sm text-gray-600">CEO-Founder</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item w-40 md:w-1/4">
                                    <div className="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp">
                                        <div className="thumb relative">
                                            <img className="w-full h-full object-cover rounded-lg" src={team3} alt="" />
                                            <ul className="absolute bottom-4 left-4 flex gap-2">
                                                <li>
                                                    <a href="#" className="text-white bg-blue-600 p-2 rounded-full">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="text-white bg-blue-400 p-2 rounded-full">
                                                        <i className="fab fa-twitter"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="text-white bg-red-600 p-2 rounded-full">
                                                        <i className="fab fa-pinterest-p"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="content text-center mt-4">
                                            <h5 className="title text-lg font-semibold">Samrat Bisui</h5>
                                            <span className="text-sm text-gray-600">CEO-Founder</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item w-40 md:w-1/4">
                                    <div className="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp">
                                        <div className="thumb relative">
                                            <img className="w-full h-full object-cover rounded-lg" src={team4} alt="" />
                                            <ul className="absolute bottom-4 left-4 flex gap-2">
                                                <li>
                                                    <a href="#" className="text-white bg-blue-600 p-2 rounded-full">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="text-white bg-blue-400 p-2 rounded-full">
                                                        <i className="fab fa-twitter"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="text-white bg-red-600 p-2 rounded-full">
                                                        <i className="fab fa-pinterest-p"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="content text-center mt-4">
                                            <h5 className="title text-lg font-semibold">Pranjal</h5>
                                            <span className="text-sm text-gray-600">CEO-Founder</span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="team-btn text-center mt-50">
                                <a className="main-btn" href="#">
                                    View all Members <i className="fal fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TeamAbout;
