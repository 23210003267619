import React from 'react';
// import thumb from '../../assets/images/service-details-thumb.jpg';

function DetailsService() {
    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100 md:px-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    <ul>
                                        <li>
                                            <i className="fal fa-home"></i>Fast Development
                                        </li>
                                        <li>
                                            <i className="fal fa-tag"></i>App, UX
                                        </li>
                                        <li>
                                            <i className="fal fa-user-friends"></i>20-30 Employees
                                        </li>
                                        <li>
                                            <i className="fal fa-bookmark"></i>Barqon Template
                                        </li>
                                        <li>
                                            <i className="fal fa-globe"></i>sphereai.ca/barqon
                                        </li>
                                    </ul>
                                </div>
                                {/* <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-download"></i>
                                        <span>Download docs</span>
                                    </a>
                                </div> */}
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-file-pdf"></i>
                                        <span>Characteristics</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details-content">
                                {/* <div className="thumb">
                                    <img src={thumb} alt="" />
                                </div> */}
                                <div className="content">
                                    <h3 className="title">What we're Building</h3>
                                    <p>
                                    At Barqon, we're developing an innovative AI-powered platform designed to revolutionize how businesses connect with their customers through authentic, impactful reviews. Our goal is to empower businesses by providing them with high-quality, SEO-optimized customer feedback that enhances their online visibility and boosts their search engine rankings. By harnessing the power of artificial intelligence, we enable businesses to generate genuine, trustworthy reviews that not only build credibility but also drive growth and customer engagement.
                                    </p>
                                    <span>
                                    Barqon offers businesses a significant advantage by generating genuine, SEO-optimized customer reviews, which boost online visibility, enhance credibility, and drive higher rankings on search engines, leading to increased customer trust and business growth.
                                    </span>
                                    <p>
                                    Our platform simplifies the process of collecting and managing reviews, allowing businesses to focus on what they do best. We are committed to creating digital solutions that are not only effective but also user-friendly and accessible to businesses of all sizes. Join us on our journey to reshape the digital landscape and create the best digital solutions that set new standards in the industry.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsService;
