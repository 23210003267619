import React from 'react';
import Sponser1 from '../../assets/images/sponser-1.png';
import Sponser10 from '../../assets/images/sponser-10.png';
import Sponser2 from '../../assets/images/sponser-2.png';
import Sponser3 from '../../assets/images/punjab-canteen.webp';
import Sponser4 from '../../assets/images/sponser-4.png';
import Sponser5 from '../../assets/images/sponser-5.png';
import Sponser6 from '../../assets/images/sponser-6.png';
import Sponser7 from '../../assets/images/sponser-9.png';
import Sponser8 from '../../assets/images/sponser-8.png';
import Sponser9 from '../../assets/images/hotel-de-paris.webp';
import sponserShape from '../../assets/images/sponser-shape.png';

function SponserHomeTwo({ className }) {
    return (
        <>
            <section className={`appie-sponser-area bg-gradient-to-r from-blue-50 via-purple-50 to-pink-50 py-16 relative overflow-hidden md:px-10 ${className}`}>
    <div className="container mx-auto px-4">
        <div className="text-center mb-12">
            <h3 className="text-4xl font-extrabold text-gray-900 mb-4 leading-tight">
                Trusted by
            </h3>
            <p className="text-lg text-gray-700 max-w-2xl mx-auto">
                Join over 40,000 businesses worldwide.
            </p>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-8 mb-12 ">
            <div className="sponser-item transform transition duration-300 hover:scale-105 hover:shadow-2xl p-4 bg-white rounded-lg ">
                <img src={Sponser1} alt="" className="h-20 mx-auto" />
            </div>
            <div className="sponser-item transform transition duration-300 hover:scale-105 hover:shadow-2xl p-4 bg-white rounded-lg">
                <img src={Sponser2} alt="" className="h-20 mx-auto" />
            </div>
            <div className="sponser-item transform transition duration-300 hover:scale-105 hover:shadow-2xl p-4 bg-white rounded-lg">
                <img src={Sponser3} alt="" className="h-20 mx-auto" />
            </div>
            <div className="sponser-item transform transition duration-300 hover:scale-105 hover:shadow-2xl p-4 bg-white rounded-lg">
                <img src={Sponser4} alt="" className="h-20 mx-auto" />
            </div>
            <div className="sponser-item transform transition duration-300 hover:scale-105 hover:shadow-2xl p-4 bg-white rounded-lg">
                <img src={Sponser5} alt="" className="h-20 mx-auto" />
            </div>
            <div className="sponser-item transform transition duration-300 hover:scale-105 hover:shadow-2xl p-4 bg-white rounded-lg">
                <img src={Sponser6} alt="" className="h-20 mx-auto" />
            </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-8">
            <div className="sponser-item transform transition duration-300 hover:scale-105 hover:shadow-2xl p-4 bg-white rounded-lg">
                <img src={Sponser7} alt="" className="h-20 mx-auto" />
            </div>
            <div className="sponser-item transform transition duration-300 hover:scale-105 hover:shadow-2xl p-4 bg-white rounded-lg">
                <img src={Sponser8} alt="" className="h-20 mx-auto" />
            </div>
            <div className="sponser-item transform transition duration-300 hover:scale-105 hover:shadow-2xl p-4 bg-white rounded-lg">
                <img src={Sponser9} alt="" className="h-20 mx-auto" />
            </div>
            <div className="sponser-item transform transition duration-300 hover:scale-105 hover:shadow-2xl p-4 bg-white rounded-lg">
                <img src={Sponser10} alt="" className="h-20 mx-auto" />
            </div>
        </div>
    </div>
    <div className="absolute inset-0 pointer-events-none">
        <img src={sponserShape} alt="" className="w-full h-full object-cover opacity-5" />
    </div>
</section>

        </>
    );
}

export default SponserHomeTwo;

