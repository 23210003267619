import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle.js';
import StickyMenu from '../../lib/StickyMenu.js';
import BackToTop from '../../components/BackToTop.jsx';
import FooterHomeTwo from '../../components/FooterHomeTwo.jsx';
import ProjectHome from './ProjectHome.jsx';
import Drawer from '../../components/Mobile/Drawer.jsx';
import DetailsService from './DetailsService.jsx';
import HeaderHomeTwo from '../../components/HeaderHomeTwo.jsx';
import HeroService from './HeroService.jsx';

function Service() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (     
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeTwo action={drawerAction.toggle} />
            <HeroService />
            <DetailsService />
            <ProjectHome />
            <FooterHomeTwo />
            <BackToTop />
        </>
    );
}

export default Service;
