import React from 'react';
import useToggle from '../../Hooks/useToggle.js';
import BackToTop from '../../components/BackToTop.jsx';
import SignupHome from './SignupHome.jsx';
import FooterHomeTwo from '../../components/FooterHomeTwo.jsx';
// import SponserHomeTwo from '../Home/SponserHomeTwo.jsx';
import Drawer from '../../components/Mobile/Drawer.jsx';
import HeaderHomeTwo from '../../components/HeaderHomeTwo.jsx';
import HeroAbout from './HeroAbout.jsx';
import TestimonialHomeTwo from '../Home/TestimonialHomeTwo.jsx';
import ServicesAbout from './ServicesAbout.jsx';
import TeamAbout from './TeamAbout.jsx';

function AboutUs() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeTwo drawer={drawer} action={drawerAction.toggle} />
            <HeroAbout />
            <ServicesAbout />
            <TeamAbout />
            <TestimonialHomeTwo />

            {/* <SponserHomeTwo className="appie-sponser-area " /> */}
            <SignupHome />
            <FooterHomeTwo className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default AboutUs;
