// import React from 'react';
// import signupThumb from '../../assets/images/signup-thumb.png';

// function SignupHome({ className }) {
//     return (
//         <>
//             <div className={`appie-signup-area md:px-10 ${className || ''}`}>
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-lg-12">
//                             <div className="appie-signup-box mb-5 ">
//                                 <span>No credit card required</span>
//                                 <h3 className="title">Get Started.</h3>
//                                 <form action="#">
//                                     <div className="input-box">
//                                         <input type="text" placeholder="Enter your name" />
//                                     </div>
//                                     <div className="input-box">
//                                         <input type="email" placeholder="Enter your email" />
//                                     </div>
//                                     <div className="input-box">
//                                         <button type="submit">Subscribe</button>
//                                     </div>
//                                     <div className="appie_checkbox_common checkbox_style2">
//                                         <div>
//                                             <input
//                                                 type="checkbox"
//                                                 name="checkbox2"
//                                                 id="checkbox4"
//                                             />
//                                             <label htmlFor="checkbox4">
//                                                 <span></span>By signing up you agree to our
//                                                 <a href="#">Terms & Conditions.</a>
//                                             </label>
//                                         </div>
//                                     </div>
//                                 </form>
//                                 <div className="thumb">
//                                     <img src={signupThumb} alt="" />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default SignupHome;


import React, { useState } from 'react';
import axios from 'axios'; // Ensure axios is imported
import signupThumb from '../../assets/images/signup-thumb.png';

function SignupHome({ className }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Adjust the URL to match your API endpoint
            const response = await axios.post('https://qrps.ca:5000/api/users/submit-email', { name, email });
            
            if (response.data.success) {
                setSuccess('User signed up successfully!');
                setError(null);
                setName('');
                setEmail('');
            } else {
                setError(response.data.error || 'An error occurred');
                setSuccess(null);
            }
        } catch (err) {
            console.error('Error submitting form:', err);
            setError(err.response?.data?.error || 'An unexpected error occurred');
            setSuccess(null);
        }
    };

    return (
        <div className={`appie-signup-area md:px-10 ${className || ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-signup-box mb-5">
                            <span>No credit card required</span>
                            <h3 className="title">Get Started.</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="input-box">
                                    <input
                                        type="text"
                                        placeholder="Enter your name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div className="input-box">
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="input-box">
                                    <button type="submit">Subscribe</button>
                                </div>
                                {/* <div className="appie_checkbox_common checkbox_style2">
                                    <div>
                                        <input 
                                            type="checkbox"
                                            name="checkbox2"
                                            id="checkbox4"
                                        />
                                        <label htmlFor="checkbox4">
                                            <span></span>By signing up you agree to our
                                            <a href="#">Terms & Conditions.</a>
                                        </label>
                                    </div>
                                </div> */}
                            </form>
                            {success && <p className="success-message">{success}</p>}
                            {error && <p className="error-message">{error}</p>}
                            <div className="thumb">
                                <img src={signupThumb} alt="Signup Thumbnail" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignupHome;
