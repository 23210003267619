import React from 'react';
import useToggle from '../../Hooks/useToggle.js';
import BackToTop from '../../components/BackToTop.jsx';
import Drawer from '../../components/Mobile/Drawer.jsx';
import FooterHomeTwo from '../../components/FooterHomeTwo.jsx';
import HeaderHomeTwo from '../../components/HeaderHomeTwo.jsx';
import PricingHomeTwo from './PricingHomeTwo.jsx';


function Purchase() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeTwo action={drawerAction.toggle} />
            <PricingHomeTwo />
            <FooterHomeTwo />
            <BackToTop className="back-to-top-2" />
        </>
    );
}

export default Purchase;
