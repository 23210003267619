import React, { useState } from 'react';

function PricingHomeTwo() {
    const [toggleSwitch, setSwitchValue] = useState(true);
    const handleSwitch = (e) => {
        e.preventDefault();
        setSwitchValue(!toggleSwitch);
    };

    return (
        <section className="bg-gradient-to-r from-blue-50 via-purple-50 to-pink-50 py-16 md:px-10">
            <div className="container mx-auto px-4">
                <div className="mt-5 text-center mb-12">
                    <h3 className="text-4xl font-extrabold text-gray-900 mb-4">Choose Your Plan</h3>
                    <p className="text-lg text-gray-600 mb-6">
                        Tailored pricing options to suit your needs. Enjoy exclusive benefits with our plans.
                    </p>
                    <div className="flex justify-center mb-8">
                        <div className="flex border border-gray-300 rounded-lg overflow-hidden">
                            <button
                                onClick={handleSwitch}
                                className={`py-2 px-6 text-lg font-medium transition-colors duration-300 rounded-tl-lg ${toggleSwitch ? 'bg-blue-600 text-white' : 'bg-white text-blue-600 border-r border-gray-300'} hover:bg-blue-500 hover:text-white`}
                            >
                                Monthly
                            </button>
                            <button
                                onClick={handleSwitch}
                                className={`py-2 px-6 text-lg font-medium transition-colors duration-300 rounded-tr-lg ${!toggleSwitch ? 'bg-blue-600 text-white' : 'bg-white text-blue-600 border-l border-gray-300'} hover:bg-blue-500 hover:text-gray-400`}
                            >
                                Yearly
                            </button>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {/* Fresh Plan */}
                    <div className={`bg-white shadow-lg rounded-xl overflow-hidden transform transition-transform hover:scale-105 ${toggleSwitch ? 'border-gray-200' : 'border-gray-300'} border-2`}>
                        <div className="p-8 text-center">
                            <h6 className="text-2xl font-semibold text-gray-800 mb-2">Fresh</h6>
                            <div className="text-5xl font-bold text-gray-900 mb-2">
                                <sup className="text-xl">$</sup> <span>27</span>
                                <p className="text-base text-gray-500">/month</p>
                            </div>
                            <p className="text-gray-600 mb-6">Comprehensive features with enhanced security. Billed $324 annually.</p>
                        </div>
                        <div className="bg-gradient-to-r from-blue-100 to-blue-200 p-12">
                            <ul className="space-y-3 mb-4 text-gray-600">
                                <li className="flex items-center"><i className="fas fa-check-circle text-blue-600 mr-2" /> 60-day chat history</li>
                                <li className="flex items-center"><i className="fas fa-check-circle text-blue-600 mr-2" /> Data security</li>
                                <li className="flex items-center"><i className="fas fa-check-circle text-blue-600 mr-2" /> 100 GB cloud storage</li>
                                <li className="flex items-center"><i className="fas fa-check-circle text-blue-600 mr-2" /> 24/7 Support</li>
                            </ul>
                            <div className="text-center">
                                <a className="inline-block bg-pink-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-pink-500 transition-colors duration-300" href="#">
                                    Choose Plan
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* Sweet Plan */}
                    <div className={`bg-white shadow-lg rounded-xl overflow-hidden transform transition-transform hover:scale-105 ${toggleSwitch ? 'border-gray-200' : 'border-gray-300'} border-2 relative`}>
                        <div className="p-8 text-center">
                            <h6 className="text-2xl font-semibold text-gray-800 mb-2">Sweet</h6>
                            <div className="text-5xl font-bold text-gray-900 mb-2">
                                <sup className="text-xl">$</sup> <span>27</span>
                                <p className="text-base text-gray-500">/month</p>
                            </div>
                            <p className="text-gray-600 mb-6">Comprehensive features with enhanced security. Billed $324 annually.</p>
                        </div>
                        <div className="absolute center-0 left-1/2 transform -translate-x-1/2 mt-2">
                            <div className="bg-green-600 text-white text-sm font-semibold py-2 px-4 rounded-full shadow-lg">
                                Most Popular
                            </div>
                        </div>
                        <div className="bg-gradient-to-r from-green-100 to-green-200 p-12">
                            <ul className="space-y-3 mb-4 text-gray-600">
                                <li className="flex items-center"><i className="fas fa-check-circle text-green-600 mr-2" /> 60-day chat history</li>
                                <li className="flex items-center"><i className="fas fa-check-circle text-green-600 mr-2" /> Data security</li>
                                <li className="flex items-center"><i className="fas fa-check-circle text-green-600 mr-2" /> 100 GB cloud storage</li>
                                <li className="flex items-center"><i className="fas fa-check-circle text-green-600 mr-2" /> 24/7 Support</li>
                            </ul>
                            <div className="text-center">
                                <a className="inline-block bg-pink-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-pink-500 transition-colors duration-300" href="#">
                                    Choose Plan
                                </a>
                            </div>
                        </div>
                        
                        
                    </div>
                    {/* Juicy Plan */}
                    <div className={`bg-white shadow-lg rounded-xl overflow-hidden transform transition-transform hover:scale-105 ${toggleSwitch ? 'border-gray-200' : 'border-gray-300'} border-2`}>
                        <div className="p-8 text-center">
                            <h6 className="text-2xl font-semibold text-gray-800 mb-2">Juicy</h6>
                            <div className="text-5xl font-bold text-gray-900 mb-2">
                                <sup className="text-xl">$</sup> <span>27</span>
                                <p className="text-base text-gray-500">/month</p>
                            </div>
                            <p className="text-gray-600 mb-6">Comprehensive features with enhanced security. Billed $324 annually.</p>
                        </div>
                        <div className="bg-gradient-to-r from-pink-100 to-pink-200 p-12">
                            <ul className="space-y-3 mb-4 text-gray-600">
                                <li className="flex items-center"><i className="fas fa-check-circle text-pink-600 mr-2" /> 60-day chat history</li>
                                <li className="flex items-center"><i className="fas fa-check-circle text-pink-600 mr-2" /> Data security</li>
                                <li className="flex items-center"><i className="fas fa-check-circle text-pink-600 mr-2" /> 100 GB cloud storage</li>
                                <li className="flex items-center"><i className="fas fa-check-circle text-pink-600 mr-2" /> 24/7 Support</li>
                            </ul>
                            <div className="text-center">
                                <a className="inline-block bg-pink-600 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-pink-500 transition-colors duration-300" href="#">
                                    Choose Plan
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Yearly Pricing */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
                    {/* Repeat the same structure with different pricing for yearly */}
                </div>
            </div>
        </section>
    );
}

export default PricingHomeTwo;




