
// import React from 'react';
// import counterIconOne from '../../assets/images/icon/counter-icon-1.svg';
// import counterIconTwo from '../../assets/images/icon/counter-icon-2.svg';
// import counterIconThree from '../../assets/images/icon/counter-icon-3.svg';
// import counterIconFour from '../../assets/images/icon/counter-icon-4.svg';
// import CounterUpCom from '../../lib/CounterUpCom.jsx';

// function CounterArea({ style }) {
//     return (
//         <section className="appie-counter-area bg-gradient-to-r from-blue-50 via-purple-50 to-pink-50 pt-20 pb-40 md:px-10" id="counter" style={style}>
//             <div className="container mx-auto">
//                 <div className="row">
//                     <div className="col-lg-12 text-center mb-10">
//                         <div className="appie-section-title">
//                             <h3 className="appie-title text-3xl font-bold">Milestones</h3>
//                             <p className="text-lg mt-2">
//                                 The full monty spiffing good time no biggie cack grub fantastic.
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="flex flex-wrap justify-center">
//                     <div className="w-full md:w-1/2 lg:w-1/4 p-4">
//                         <div className="appie-single-counter mt-6 wow fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
//                             <div className="counter-content text-center p-6 bg-white shadow-lg rounded-lg">
//                                 <div className="icon mb-4">
//                                     <img src={counterIconOne} alt="Active Installation" className="mx-auto" />
//                                 </div>
//                                 <h3 className="title text-2xl font-bold">
//                                     <span className="counter-item text-primary">
//                                         <CounterUpCom endValue={100} sectionSelect="counter" />
//                                     </span>
//                                     k+
//                                 </h3>
//                                 <p className="text-gray-500 mt-2">Active Installation</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="w-full md:w-1/2 lg:w-1/4 p-4">
//                         <div className="appie-single-counter mt-6 wow fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
//                             <div className="counter-content text-center p-6 bg-white shadow-lg rounded-lg">
//                                 <div className="icon mb-4">
//                                     <img src={counterIconTwo} alt="Active Installation" className="mx-auto" />
//                                 </div>
//                                 <h3 className="title text-2xl font-bold">
//                                     <span className="counter-item text-primary">
//                                         <CounterUpCom endValue={108} sectionSelect="counter" />
//                                     </span>
//                                     +
//                                 </h3>
//                                 <p className="text-gray-500 mt-2">Active Installation</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="w-full md:w-1/2 lg:w-1/4 p-4">
//                         <div className="appie-single-counter mt-6 wow fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
//                             <div className="counter-content text-center p-6 bg-white shadow-lg rounded-lg">
//                                 <div className="icon mb-4">
//                                     <img src={counterIconThree} alt="Active Installation" className="mx-auto" />
//                                 </div>
//                                 <h3 className="title text-2xl font-bold">
//                                     <span className="counter-item text-primary">
//                                         <CounterUpCom endValue={307} sectionSelect="counter" />
//                                     </span>
//                                     +
//                                 </h3>
//                                 <p className="text-gray-500 mt-2">Active Installation</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="w-full md:w-1/2 lg:w-1/4 p-4">
//                         <div className="appie-single-counter mt-6 wow fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
//                             <div className="counter-content text-center p-6 bg-white shadow-lg rounded-lg">
//                                 <div className="icon mb-4">
//                                     <img src={counterIconFour} alt="Active Installation" className="mx-auto" />
//                                 </div>
//                                 <h3 className="title text-2xl font-bold">
//                                     <span className="counter-item text-primary">
//                                         <CounterUpCom endValue={725} sectionSelect="counter" />
//                                     </span>
//                                     k+
//                                 </h3>
//                                 <p className="text-gray-500 mt-2">Active Installation</p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     );
// }

// export default CounterArea;

import React from 'react';
import counterIconOne from '../../assets/images/icon/counter-icon-1.svg';
import counterIconTwo from '../../assets/images/icon/counter-icon-2.svg';
import counterIconThree from '../../assets/images/icon/counter-icon-3.svg';
import counterIconFour from '../../assets/images/icon/counter-icon-4.svg';
import CounterUpCom from '../../lib/CounterUpCom.jsx';

function CounterArea({ style }) {
    return (
        <section className="appie-counter-area bg-gradient-to-r from-blue-50 via-purple-50 to-pink-50 pt-20 pb-40 md:px-10" id="counter" style={style}>
            <div className="container mx-auto">
                <div className="row">
                    <div className="col-lg-12 text-center mb-10">
                        <div className="appie-section-title">
                            <h3 className="appie-title text-3xl font-bold">Milestones</h3>
                            <p className="text-lg mt-2">
                                The full monty spiffing good time no biggie cack grub fantastic.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap justify-center">
                    <div className="w-full md:w-1/2 lg:w-1/4 p-4">
                        <div className="appie-single-counter mt-6 wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="100ms">
                            <div className="counter-content text-center p-6 bg-white shadow-lg rounded-lg">
                                <div className="icon mb-4">
                                    <img src={counterIconOne} alt="Active Installation" className="mx-auto" />
                                </div>
                                <h3 className="title text-2xl font-bold">
                                    <span className="counter-item text-primary">
                                        <CounterUpCom endValue={100} duration={2} sectionSelect="counter" />
                                    </span>
                                    k+
                                </h3>
                                <p className="text-gray-500 mt-2">Active Installation</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/4 p-4">
                        <div className="appie-single-counter mt-6 wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="200ms">
                            <div className="counter-content text-center p-6 bg-white shadow-lg rounded-lg">
                                <div className="icon mb-4">
                                    <img src={counterIconTwo} alt="Active Installation" className="mx-auto" />
                                </div>
                                <h3 className="title text-2xl font-bold">
                                    <span className="counter-item text-primary">
                                        <CounterUpCom endValue={108} duration={2} sectionSelect="counter" />
                                    </span>
                                    +
                                </h3>
                                <p className="text-gray-500 mt-2">Active Installation</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/4 p-4">
                        <div className="appie-single-counter mt-6 wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">
                            <div className="counter-content text-center p-6 bg-white shadow-lg rounded-lg">
                                <div className="icon mb-4">
                                    <img src={counterIconThree} alt="Active Installation" className="mx-auto" />
                                </div>
                                <h3 className="title text-2xl font-bold">
                                    <span className="counter-item text-primary">
                                        <CounterUpCom endValue={307} duration={2} sectionSelect="counter" />
                                    </span>
                                    +
                                </h3>
                                <p className="text-gray-500 mt-2">Active Installation</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/4 p-4">
                        <div className="appie-single-counter mt-6 wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="400ms">
                            <div className="counter-content text-center p-6 bg-white shadow-lg rounded-lg">
                                <div className="icon mb-4">
                                    <img src={counterIconFour} alt="Active Installation" className="mx-auto" />
                                </div>
                                <h3 className="title text-2xl font-bold">
                                    <span className="counter-item text-primary">
                                        <CounterUpCom endValue={725} duration={2} sectionSelect="counter" />
                                    </span>
                                    k+
                                </h3>
                                <p className="text-gray-500 mt-2">Active Installation</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CounterArea;
